.x-table th {
    background-color: #d6dddd;
}

.image-wrapper {
    position: relative;
}

.remove-img {
    position: absolute;
}

.remove-img button {
    width: 30px !important;
}

#calNav {
    position: fixed;
    z-index: 99999;
    display: flex;
    flex-direction: column;
}



body {
    background-color: #d7d4d41f;
    min-height: 100vh;
}

table#costTable tr td:not(td:first-of-type) {
    text-align: right;
}

table#costTable th:not(th:first) {
    text-align: right !important;
}

table#costTable p {
    margin: 0.5rem !important;
}

#costTable tbody tr td:not(:first-of-type) {
    padding-right: 4.5rem;
}


@media only screen and (max-width: 764px) {
    #costTable tbody tr td:not(:first-of-type) {
        padding-right: 0;
    }
}

@media print {

    #calNav,
    button {
        display: none !important;
    }

    #printLogo {
        display: block !important;
        position: fixed;
        top: 0;
    }

    #footer {
        display: block !important;
        position: fixed;
        bottom: 1%;
        left: 40%;
    }

    .page-break {
        display: block !important;
    }

    @page {
        margin-top: 30px;
        margin-bottom: 0;
    }

    .chart-wrapper canvas {
        width: 850px !important
    }
}

@media screen {
    #printLogo {
        display: none;
    }

    #footer {
        display: none;
    }

    .page-break {
        display: none;
    }
}

/* .chart-wrapper canvas {
    height: 500px !important;
} */

[data-tag="allowRowEvents"] {
    white-space: inherit !important;

}

.chart-wrapper {
    min-height: 250px !important;
    min-width: 500px !important;
}

#myNav {
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
    color: #1e386b;
}

.logo {
    margin-right: 4rem;
}

.menu-item-wrapper {
    padding-top: 10px;
}

#charts .card{
   overflow: auto;
}