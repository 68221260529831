nav a:not([id^="cal-tabs"]) {
  color: #1e386b !important;
}


.nav-item .dropdown-item {
  color: #1e386b;
}

.my-nav-item {
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 6px;
  font-weight: 500;
}

.my-nav-item a:hover {
  text-decoration: underline;
}

.navbar-nav>* {
  margin-left: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #343a40 !important;
}



..btn-outline-secondary {
  color: #1e386b !important;
  border-color: #1e386b !important;
}

.btn-outline-secondary:hover {
  background-color: #1e386b !important;
}

.btn-secondary {
  background-color: #1e386b !important;
  border-color: #1e386b !important;
}

#pdfWrapper > div > div{
  max-width:1000px;
  overflow: scroll;
}